import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { Helpers } from '../helpers';

const columns = [
  {
    title: 'id',
    sorter: true,
    dataIndex: '_id',
    width: '20%',
    key: '_id',
    render: (text: string) => {
      return <Link to={`/guideEntries/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: '20%',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: true,
    width: '20%',
    render: (c: any) => {
      return c.name;
    },
  },
  {
    title: 'createdBy',
    dataIndex: 'createdBy',
    render: (user: any) => {
      if (!user) return null;
      return (
        <span>
          {user.firstName} {user.lastName}
        </span>
      );
    },
    key: 'createdBy',
    sorter: true,
    width: '20%',
  },
  {
    title: 'updatedBy',
    dataIndex: 'updatedBy',
    sorter: true,
    render: (user: any) => {
      if (!user) return null;
      return (
        <span>
          {user.firstName} {user.lastName}
        </span>
      );
    },
  },
  {
    title: 'updatedAt',
    dataIndex: 'updatedAt',
    width: '20%',
    sorter: true,
    render: (date: Date) => {
      return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    width: '20%',
    sorter: true,
    render: (date: Date) => {
      return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
  },
];

const GuideEntryTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Table
        tableId={'guideEntriesTable'}
        deleteApiCall={Api.guideEntries.delete}
        allowDelete={true}
        restoreApiCall={Api.guideEntries.restore}
        actions={(record: any) => <Link to={`/guideEntries/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.guideEntries.table}
        filterDefinitions={[
          {
            key: '_id',
            type: 'number',
            label: 'ID',
            span: 3,
          },
          {
            key: 'name',
            type: 'search',
            label: 'Name',
          },
          {
            key: 'category',
            type: 'multiValue',
            label: 'Category',
            apiFetchCall: Api.guideCategories.list,
            dataMappingFunction: (data: any) => {
              return data.map((i: any) => ({ label: i.name, value: i._id }));
            },
          },
        ]}
        columns={columns}
        exportApiCall={{
          csv: Api.guideEntries.exportByCSV,
        }}
      />
    </Space>
  );
};

export default GuideEntryTable;
