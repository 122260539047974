import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Api } from '../api';
import { useCitiesList } from '../api/useCitiesList';
import { useRegionsList } from '../api/useRegionsList';

const CitySelector: React.FC<any> = ({ value, onChange }) => {
  const [data, loading, reload] = useCitiesList();
  const [creating, setIsCreating] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const onAddCategory = () => {
    if (inputValue.trim() !== '' && !creating) {
      setIsCreating(true);
      Api.cities
        .create({ name: inputValue })
        .then(() => {
          setInputValue('');
          setIsCreating(false);
          reload();
        })
        .catch(() => {
          setInputValue('');
          setIsCreating(false);
        });
    }
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      showSearch
      optionFilterProp="label"
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              disabled={creating}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              size="small"
              style={{ flex: 'auto' }}
            />
            <div onClick={onAddCategory} style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}>
              <PlusOutlined /> Add City
            </div>
          </div>
        </div>
      )}
      loading={loading}
      style={{ width: '100%' }}
      options={data.map((i) => ({
        label: i.name,
        value: i._id,
        key: i._id,
      }))}
    />
  );
};

export default CitySelector;
