import {
	Button,
	Col,
	Input,
	message,
	Result,
	Row,
	Select,
	Space,
	Switch,
} from "antd";
import React from "react";
import { Controller, type Path, useFieldArray, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { FieldErrorMessage } from "./FieldErrorMessage";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";
import EmailArrayField from "./EmailsArrayField";
import PhoneArrayFieldSimple from "./PhoneArrayFieldSimple";
import { TagsInput } from "./TagsInput";
import SelectWithApiData from "./SelectWithApiData";

export const priorityOptions = [
	{ label: "None", value: "0", backgroundColor: "#808080" },
	{ label: "Free", value: "1", backgroundColor: "#7844F0" },
	{ label: "Low", value: "2", backgroundColor: "#F2AB3C" },
	{ label: "Medium Minus", value: "3", backgroundColor: "#A9C880" },
	{ label: "Medium Plus", value: "4", backgroundColor: "#CCD143" },
	{ label: "High", value: "5", backgroundColor: "#2356DF" },
	{ label: "Very High", value: "6", backgroundColor: "#C53E2D" },
];

type PeopleFormType = {
	firstName: string;
	lastName: string;
	isEntity: boolean;
	socialMedia: {
		facebook: string;
		instagram: string;
	};
	gpsCoordinates: string;
	generalNotes: string;
	nameInEnglish: string;
	address: string;
	phones: string[];
	emails: string[];
	contactPerson: string;
	title: string;
	company: string;
	jobTitle: string;
	website: string;
	category: string;
	image: string;
	tagsV2: [string];
	priority: string;
};

const PeopleForm = () => {
	const { id } = useParams<{ id: string }>();
	// const [notFound, setNotFound] = useState(false);
	const {
		handleSubmit,
		formState: { errors },
		control,

		reset,
	} = useForm<PeopleFormType>({
		defaultValues: {
			firstName: undefined,
			lastName: undefined,
			isEntity: false,
			socialMedia: {
				facebook: undefined,
				instagram: undefined,
			},
			gpsCoordinates: undefined,
			generalNotes: undefined,
			nameInEnglish: undefined,
			address: undefined,
			phones: [],
			emails: [],
			contactPerson: undefined,
			title: undefined,
			company: undefined,
			jobTitle: undefined,
			website: undefined,
			category: undefined,
			image: undefined,
			tagsV2: [],
			priority: "0",
		},
	});

	const history = useHistory();
	const onSuccess = () => {
		message.success("Saved");
		history.push("/people");
	};
	// const imageFields = [
	//     {
	//         name: "image",
	//         singleValue: true,
	//     }
	// ];

	const [notFound, loading] = useLoadFormData({
		apiCall: Api.people.single,
		id,
		setValue: reset,
		//  imageFields
	});
	// biome-ignore lint/style/useSingleVarDeclarator: <explanation>
	const [loadingSubmit, submit] = useSubmitForm({
			createApiCall: Api.people.create,
			updateApiCall: Api.people.update,
			onSuccess,
			//  imageFields,
			updateId: id,
		}),
		phoneNumbersFieldArray = useFieldArray({
			name: "phones" as never,
			control,
		}),
		emailFieldArray = useFieldArray({ name: "emails" as never, control });
	if (notFound)
		return (
			<Result
				status="404"
				title="404"
				subTitle="Sorry, document does not exist"
				extra={
					<Button type="default">
						<Link to="/people">Back to list</Link>
					</Button>
				}
			/>
		);

	return (
		<form className="white-container" onSubmit={handleSubmit(submit)}>
			<Space size="middle" style={{ width: "100%" }} direction="vertical">
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"title"}>Title</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Select {...field} style={{ width: "100%" }}>
									<Select.Option value={"Mr"}>Mr</Select.Option>
									<Select.Option value={"Mrs"}>Mrs</Select.Option>
									<Select.Option value={"Miss"}>Miss</Select.Option>
									<Select.Option value={"Ms"}>Ms</Select.Option>
									<Select.Option value={"Dr"}>Dr</Select.Option>
								</Select>
							)}
							name="title"
						/>
						<FieldErrorMessage errors={errors} name="title" />
					</Col>
					<Col span={6}>
						<label htmlFor={"firstName"}>First Name</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} id={"firstName"} />}
							name="firstName"
							rules={{ required: true }}
						/>
						<FieldErrorMessage errors={errors} name="firstName" />
					</Col>
					<Col span={6}>
						<label htmlFor={"lastName"}>Last Name</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} id={"lastName"} />}
							name="lastName"
							rules={{ required: true }}
						/>
						<FieldErrorMessage errors={errors} name="lastName" />
					</Col>
					<Col span={2}>
						<label htmlFor={"isEntity"}>Entity</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Switch
									onChange={field.onChange}
									checked={field.value}
									defaultChecked={field.value}
									style={{ display: "block", marginTop: 3 }}
								/>
							)}
							name="isEntity"
						/>
						<FieldErrorMessage errors={errors} name="isEntity" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"image"}>Image</label>
						<Controller
							control={control}
							render={({ field }) => <FileUploadV2 singleValue {...field} />}
							name="image"
						/>
						<FieldErrorMessage errors={errors} name="image" />
					</Col>
					<Col span={6}>
						<label htmlFor={"priority"}>Priority</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									style={{ width: "100%" }}
									tagRender={({ label, value }) => {
										const priority = priorityOptions[value ? Number(value) : 0];
										return (
											<span
												style={{
													padding: "2px 6px",
													borderRadius: 4,
													backgroundColor: priority.backgroundColor,
													color: "white",
													fontWeight: "bold",
												}}
											>
												{priority.label}
											</span>
										);
									}}
								>
									{priorityOptions.map(({ label, value, backgroundColor }) => (
										<Select.Option key={value} value={value}>
											<span
												style={{
													padding: "2px 6px",
													borderRadius: 4,
													backgroundColor,
													color: "white",
													fontWeight: "bold",
												}}
											>
												{label}
											</span>
										</Select.Option>
									))}
								</Select>
							)}
							name="priority"
						/>
						<FieldErrorMessage errors={errors} name="title" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={12}>
						<EmailArrayField
							control={control}
							errors={errors}
							fieldName="emails"
							useFieldArrayFields={emailFieldArray}
						/>
					</Col>
					<Col span={12}>
						<PhoneArrayFieldSimple
							control={control}
							errors={errors}
							fieldName="phones"
							useFieldArrayFields={phoneNumbersFieldArray}
						/>
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={12}>
						<label htmlFor={"gpsCoordinates"}>GPS Coordinates</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} id={"gpsCoordinates"} />}
							name="gpsCoordinates"
						/>
						<FieldErrorMessage errors={errors} name="gpsCoordinates" />
					</Col>
					<Col span={6}>
						<label htmlFor={"contactPerson"}>Contact</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input {...field} title={"contactPerson"} />
							)}
							name="contactPerson"
						/>
						<FieldErrorMessage errors={errors} name="contactPerson" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"jobTitle"}>Job Title</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} title={"jobTitle"} />}
							name="jobTitle"
						/>
						<FieldErrorMessage errors={errors} name="jobTitle" />
					</Col>
					<Col span={6}>
						<label htmlFor={"company"}>Company</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} title={"company"} />}
							name="company"
						/>
						<FieldErrorMessage errors={errors} name="company" />
					</Col>
					<Col span={6}>
						<label htmlFor={"nameInEnglish"}>Name In English</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input {...field} title={"nameInEnglish"} />
							)}
							name="nameInEnglish"
						/>
						<FieldErrorMessage errors={errors} name="nameInEnglish" />
					</Col>
				</Row>

				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"category"}>Category</label>
						<Controller
							control={control}
							render={({ field }) => (
								<SelectWithApiData
									labelField="name"
									{...field}
									apiAddCall={Api.categories.create}
									apiFetchCall={Api.categories.list}
								/>
							)}
							name="category"
						/>
						<FieldErrorMessage errors={errors} name="category" />
					</Col>
					<Col span={6}>
						<label htmlFor={"tagsV2"}>Tags</label>
						<Controller
							control={control}
							render={({ field }) => <TagsInput {...field} id="tagsV2" />}
							name="tagsV2"
						/>
						<FieldErrorMessage errors={errors} name="tagsV2" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"website"}>Website</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} title={"website"} />}
							name="website"
						/>
						<FieldErrorMessage errors={errors} name="website" />
					</Col>
					<Col span={8}>
						<label htmlFor={"socialMedia.facebook"}>Facebook</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input {...field} title={"socialMedia.facebook"} />
							)}
							name="socialMedia.facebook"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.facebook" />
					</Col>
					<Col span={8}>
						<label htmlFor={"socialMedia.instagram"}>Instagram</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input {...field} title={"socialMedia.instagram"} />
							)}
							name="socialMedia.instagram"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.instagram" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={12}>
						<label htmlFor={"address"}>Address</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input.TextArea {...field} id={"address"} />
							)}
							name="address"
						/>
						<FieldErrorMessage errors={errors} name="address" />
					</Col>
				</Row>
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={24}>
						<label htmlFor={"generalNotes"}>Notes</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Input.TextArea {...field} title={"generalNotes"} />
							)}
							name="generalNotes"
						/>
						<FieldErrorMessage errors={errors} name="generalNotes" />
					</Col>
				</Row>
			</Space>
			<div style={{ marginBottom: 10 }} />
			<Button loading={loading || loadingSubmit} htmlType="submit">
				Submit
			</Button>
		</form>
	);
};

export default PeopleForm;
