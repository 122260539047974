import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
import BasicTableFilter from "./BasicTableFilter";
//import { CategoriesFilter } from './CategoriesFilter';

const columns = [
	{
		title: "id",
		sorter: true,
		dataIndex: "_id",
		width: "20%",
		key: "_id",
		render: (text: string) => {
			return <Link to={`/pages/update/${text}`}>{text}</Link>;
		},
	},
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
		sorter: true,
		width: "20%",
	},
];

const PageTable = () => {
	return (
		<Space style={{ width: "100%" }} direction="vertical">
			{/* <CategoriesFilter /> */}
			<Table
				tableId="pagesTable"
				actions={(record: any) => (
					<Link to={`/pages/update/${record._id}`}>View</Link>
				)}
				Filter={BasicTableFilter}
				apiCall={Api.pages.table}
				columns={columns}
			/>
		</Space>
	);
};

export default PageTable;
