import { Button, message, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Api } from '../api';
import { useSubmitForm } from '../helpers/forms';
import { useLoadFormData } from '../hooks/useLoadFormData';

const PageForm = () => {
  let { id }: any = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm();
  let history = useHistory();
  const [Component, setComponent]: [any, any] = useState(null);

  const onSuccess = () => {
    message.success('Saved');
    history.push('/pages');
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading, data] = useLoadFormData({
    apiCall: Api.pages.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.pages.create,
    updateApiCall: Api.pages.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  useEffect(() => {
    if (data && data.component) setComponent(React.lazy(() => import(`./${data.component}`)));
    if (data && !data.component) {
      setComponent(React.lazy(() => import(`./DefaultPageManager`)));
    }
  }, [data]);

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/pages">Back to list</Link>
          </Button>
        }
      />
    );
  if (!Component) return <p>Loading</p>;
  else {
    return <Component pageData={data} />;
  }
};

export default PageForm;
