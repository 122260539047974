import {
  DashboardOutlined,
  FolderOutlined,
  GlobalOutlined,
  GroupOutlined,
  MailOutlined,
  PlusOutlined,
  TagsOutlined,
  TeamOutlined,
  UploadOutlined,
} from '@ant-design/icons/lib';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Api } from './api';
import CityForm from './components/CityForm';
import CityTable from './components/CityTable';
import ContactFormForm from './components/ContactFormForm';
import ContactFormTable from './components/ContactFormTable';
import DataImportForm from './components/DataImportForm';
import DataImportTable from './components/DataImportTable';
import DistrictForm from './components/DistrictForm';
import DistrictTable from './components/DistrictTable';
import GuideCategoryForm from './components/GuideCategoryForm';
import GuideCategoryTable from './components/GuideCategoryTable';
import GuideEntryForm from './components/GuideEntryForm';
import GuideEntryTable from './components/GuideEntryTable';
import PackageForm from './components/PackageForm';
import PackageTable from './components/PackageTable';
import PageForm from './components/PageForm';
import PageTable from './components/PageTable';
import RegionForm from './components/RegionForm';
import RegionTable from './components/RegionTable';
import TagForm from './components/TagForm';
import TagTable from './components/TagTable';
import PeopleTable from './components/PeopleTable';
import PeopleForm from './components/PeopleForm';
const CategoriesForm = React.lazy(() => import('./components/CategoriesCreate'));
const CategoriesTable = React.lazy(() => import('./components/CategoriesList'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const UsersForm = React.lazy(() => import('./components/UsersCreate'));
const UsersList = React.lazy(() => import('./components/UsersList'));

export const Routes: any = [
  {
    title: 'Dashboard',
    component: Dashboard,
    icon: DashboardOutlined,
    hideFromMenu: true,
    breadcrumb: 'I love sandwiches',

    path: '/',
    isHomePage: true,
  },
  {
    title: 'Tags',
    icon: TagsOutlined,
    path: '/tags',
    dataRoute: Api.tags.statistics,
    component: TagTable,
    buttons: [
      <Link key="create" to="/tags/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Tag
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/tags/create',
        component: TagForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/tags/update/:id',
        component: TagForm,
      },
    ],
  },

  {
    title: 'System Users',
    icon: TeamOutlined,
    path: '/users',
    buttons: [
      <Link key="create" to="/users/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add User
        </Button>
      </Link>,
    ],
    component: UsersList,
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/users/create',
        component: UsersForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/users/update/:id',
        component: UsersForm,
      },
    ],
  },
  {
    title: 'People',
    icon: TeamOutlined,
    path: '/people',
    dataRoute: Api.people.statistics,
    component: PeopleTable,
    buttons: [
      <Link key="create" to="/people/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Contact
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/people/create',
        component: PeopleForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/people/update/:id',
        component: PeopleForm,
      },
    ],
  },
  {
    title: 'Guide Categories',
    icon: GroupOutlined,
    path: '/guideCategories',
    dataRoute: Api.guideCategories.statistics,
    component: GuideCategoryTable,
    buttons: [
      <Link key="create" to="/guideCategories/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Category
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/guideCategories/create',
        component: GuideCategoryForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/guideCategories/update/:id',
        component: GuideCategoryForm,
      },
    ],
  },
  {
    title: 'Guide Entry',
    icon: GroupOutlined,
    path: '/guideEntries',
    dataRoute: Api.guideEntries.statistics,
    component: GuideEntryTable,
    buttons: [
      <Link key="create" to="/guideEntries/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Guide
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/guideEntries/create',
        component: GuideEntryForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/guideEntries/update/:id',
        component: GuideEntryForm,
      },
    ],
  },

  {
    title: 'Guide Packages',
    icon: FolderOutlined,
    path: '/packages',
    dataRoute: Api.packages.statistics,
    component: PackageTable,
    buttons: [
      <Link key="create" to="/packages/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Guide
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/packages/create',
        component: PackageForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/packages/update/:id',
        component: PackageForm,
      },
    ],
  },

  {
    title: 'Regions',
    icon: GlobalOutlined,
    path: '/regions',
    dataRoute: Api.regions.statistics,
    component: RegionTable,
    buttons: [
      <Link key="create" to="/regions/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Region
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/regions/create',
        component: RegionForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/regions/update/:id',
        component: RegionForm,
      },
    ],
  },

  {
    title: 'Districts',
    icon: GlobalOutlined,
    path: '/districts',
    dataRoute: Api.regions.statistics,
    component: DistrictTable,
    buttons: [
      <Link key="create" to="/districts/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add District
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/districts/create',
        component: DistrictForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/districts/update/:id',
        component: DistrictForm,
      },
    ],
  },

  {
    title: 'Cities',
    icon: GlobalOutlined,
    path: '/cities',
    dataRoute: Api.cities.statistics,
    component: CityTable,
    buttons: [
      <Link key="create" to="/cities/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add City
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/cities/create',
        component: CityForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/cities/update/:id',
        component: CityForm,
      },
    ],
  },
  {
    title: 'Data Import',
    icon: UploadOutlined,
    hideFromMenu: true,
    path: '/dataImports',
    dataRoute: Api.dataImports.statistics,
    component: DataImportTable,
    buttons: [
      <Link key="create" to="/dataImports/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Import
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/dataImports/create',
        component: DataImportForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/dataImports/update/:id',
        component: DataImportForm,
      },
    ],
  },

  {
    title: 'Contact',
    icon: MailOutlined,
    path: '/contact',
    dataRoute: Api.contactForms.statistics,
    component: ContactFormTable,
    buttons: [
      <Link key="create" to="/contact/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Contact
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: 'Create',
        icon: PlusOutlined,
        path: '/contact/create',
        component: ContactFormForm,
      },
      {
        title: 'Update',
        hideFromMenu: true,
        path: '/contact/update/:id',
        component: ContactFormForm,
      },
    ],
  },
  {
    title: 'Pages',
    icon: TagsOutlined,
    buttons: [],
    path: '/pages',
    dataRoute: Api.pages.statistics,
    component: PageTable,
    routes: [
      {
        title: 'Page Management',
        hidePageHeader: true,
        hideFromMenu: true,
        path: '/pages/update/:id',
        component: PageForm,
      },
    ],
  },
];
