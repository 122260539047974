import { UploadFile } from "antd/lib/upload/interface";

export const IMAGE_ROOT = "https://bamleb.s3.eu-central-1.amazonaws.com/";

export const ImagesHelpers = {
  formatAmazonImage(key: string, { width = 600, fullWidth = false, blur = 0 } = {}): string {
    const request: any = {
      bucket: "bamleb", // S3 bucket name
      key: `${key}`,
      edits: {
        // smartCrop: true, // uncomment to crop around first face, will return nothing if no face is found
        // normalize: true,
        // grayscale: true,
        // small tip, if you have a mobile app you can use the following 2 lines of code to optimise your images further
        webp: true, // if android, android loves webp
        jpeg: true, // if iOS, iOS doesnt display webp so lets convert it
        sharpen: true,
        blur,
        // rotate: 180,
        resize: {
          width: width,
        },
      },
    };
    if (request.edits.blur === 0) delete request.edits.blur;
    else {
      delete request.edits.resize;
    }
    if (fullWidth) delete request.edits.resize;
    const domain = "https://d2doisxcuz2yo7.cloudfront.net";
    const strRequest = JSON.stringify(request);
    const encRequest = btoa(strRequest);
    return `${domain}/${encRequest}`;
  },
  formatUrlOnly(value: string) {
    return `${IMAGE_ROOT}${value}`;
  },
  formatUrl(value: string) {
    return IMAGE_ROOT + encodeURIComponent(value);
  },
  formatForForm: (value: string[] | string, singleValue: boolean = false) => {
    if (singleValue) {
      if (!value) return [];
      return [
        {
          name: value,
          uid: value,
          url: IMAGE_ROOT + (value as string).replace(IMAGE_ROOT, ""),
          status: "done",
        },
      ];
    }
    return (value as string[]).map((i) => {
      return {
        name: i,
        uid: i,
        url: IMAGE_ROOT + i.replace(IMAGE_ROOT, ""),
        status: "done",
      };
    });
  },
  formatForSubmit: (images: UploadFile[] = [], singleValue: boolean = false) => {
    let filtred = images.filter((v) => {
      if (v && v.status === "done") {
        return true;
      }
      return false;
    });
    filtred = filtred.map((v) => {
      return (
        (v.url && v.url.replace(IMAGE_ROOT, "")) || v.response.path.value.replace(IMAGE_ROOT, "")
      );
    });

    if (filtred.length === 0) return singleValue ? null : [];

    if (singleValue) return filtred[0];

    return filtred;
  },
  validate: (value: UploadFile[]) => {
    let valid = false;
    value &&
      value.forEach((element: UploadFile) => {
        if (element.status === "done") valid = true;
        else valid = false;
      });
    return valid;
  },
};
