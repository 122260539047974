import {
	Button,
	Col,
	Input,
	message,
	Result,
	Row,
	Select,
	Space,
	Typography,
} from "antd";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useGuideCategoriesList } from "../api/useGuideCategoriesList";
import { useSubmitForm } from "../helpers/forms";
import { useLoadFormData } from "../hooks/useLoadFormData";
import CitySelector from "./CitySelector";
import { FieldErrorMessage } from "./FieldErrorMessage";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";
import GuideCategoriesSelector from "./GuideCategoriesSelector";
import { TagsInput } from "./TagsInput";
import TextEditorInput from "./TextEditorInput";
const defaultValues = {
	thumbnailImage: null,
	name: "",
	category: null,
	categoryType: [],
	beachEntranceFee: [],
	byReligion: [],
	typeOfMuseum: [],
	typeOfDesign: [],
	typeOfHandicraft: [],
	numberOfRooms: "",
	numberOfGuests: "",
	wineryType: [],
	pubType: [],
	bakeryType: [],
	spaType: [],
	priceRange: "",
	activities: [],
	imagesGallery: [],
	tags: [],
	text: "",
	phoneNumbers: [],
	emails: [],
	city: null,
	geolocation: {
		latitude: "",
		longitude: "",
	},
	website: "",
	socialMedia: {
		facebook: "",
		instagram: "",
		twitter: "",
		linkedIn: "",
		pinterest: "",
		youtube: "",
	},
};
const GuideEntryForm = () => {
	let { id }: any = useParams();
	// const [notFound, setNotFound] = useState(false);
	const {
		handleSubmit,
		control,
		reset,

		formState: { errors },
	} = useForm({
		// shouldUnregister: false,
		defaultValues,
	});

	const [categories] = useGuideCategoriesList();
	let history = useHistory();
	const onSuccess = () => {
		message.success("Saved");
		history.push("/guideEntries");
	};
	const category = useWatch({ name: "category", control });
	const currentCategory = categories.find((i) => i._id === category);

	// reset({},{})
	const [notFound, loading] = useLoadFormData({
		apiCall: Api.guideEntries.single,
		id,
		setValue: reset,
		defaultValues,
	});

	const [loadingSubmit, submit] = useSubmitForm({
		createApiCall: Api.guideEntries.create,
		updateApiCall: Api.guideEntries.update,
		onSuccess,
		updateId: id,
	});

	if (notFound)
		return (
			<Result
				status="404"
				title="404"
				subTitle="Sorry, document does not exist"
				extra={
					<Button type="primary">
						<Link to="/guideEntries">Back to list</Link>
					</Button>
				}
			/>
		);

	return (
		<form className="white-container" onSubmit={handleSubmit(submit)}>
			<Space size="middle" style={{ width: "100%" }} direction="vertical">
				<Row gutter={8}>
					{
						<Col span={6}>
							<label htmlFor={"name"}>Name</label>
							<Controller
								control={control}
								render={({ field }) => <Input {...field} />}
								rules={{ required: true }}
								name="name"
							/>
							<FieldErrorMessage errors={errors} name="name" />
						</Col>
					}
				</Row>
				<Row gutter={8}>
					<Col span={6}>
						<label htmlFor={"categories"}>Category</label>
						<Controller
							control={control}
							render={({ field }) => <GuideCategoriesSelector {...field} />}
							rules={{ required: true }}
							name="category"
						/>
						<FieldErrorMessage errors={errors} name="category" />
					</Col>
				</Row>
				{currentCategory?.name === "Bakeries & Sweets" && (
					<Row key="Bakeries & Sweets" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"type"}>Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Bakeries",
												value: "Bakeries",
											},
											{
												label: "Pastries",
												value: "Pastries",
											},
											{
												label: "Chocolateries",
												value: "Chocolateries",
											},
											{
												label: "Ice cream parlours",
												value: "Ice cream parlours",
											},
											{
												label: "Oriental sweets",
												value: "Oriental sweets",
											},
										]}
										mode="multiple"
										{...field}
									/>
								)}
								name="categoryType"
							/>
							<FieldErrorMessage errors={errors} name="categoryType" />
						</Col>
					</Row>
				)}
				{currentCategory?.name === "Street Food & Snacks" && (
					<Row key="Street Food & Snacks" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"type"}>Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "International",
												value: "International",
											},
											{
												label: "Lebanese",
												value: "Lebanese",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="categoryType"
							/>
							<FieldErrorMessage errors={errors} name="categoryType" />
						</Col>
					</Row>
				)}
				{currentCategory?.name === "Workshops" && (
					<Row key="Workshops" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"type"}>Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Arts & crafts",
												value: "Arts & crafts",
											},
											{
												label: "Culinary",
												value: "Culinary",
											},
											{
												label: "Language courses",
												value: "Language courses",
											},
											{
												label: "Other",
												value: "Other",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="categoryType"
							/>
							<FieldErrorMessage errors={errors} name="categoryType" />
						</Col>
					</Row>
				)}
				{currentCategory?.name === "Beaches" && (
					<Row key="beachEntranceFee" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"beachEntranceFee"}>Entrance Fee</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Free entry",
												value: "Free entry",
											},
											{
												label: "Paid entry",
												value: "Paid entry",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="beachEntranceFee"
							/>
							<FieldErrorMessage errors={errors} name="beachEntranceFee" />
						</Col>
					</Row>
				)}
				{currentCategory?.name === "Religious Sites" && (
					<Row key="byReligion" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"byReligion"}>By Religion</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Christian",
												value: "Christian",
											},
											{
												label: "Druze",
												value: "Druze",
											},
											{
												label: "Muslim",
												value: "Muslim",
											},
											{
												label: "Other ",
												value: "Other ",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="byReligion"
							/>
							<FieldErrorMessage errors={errors} name="byReligion" />
						</Col>
					</Row>
				)}
				{currentCategory?.name === "Markets & Local Producers" && (
					<Row key="type" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"type"}>Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Markets",
												value: "Markets",
											},
											{
												label: "Producers",
												value: "Producers",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="categoryType"
							/>
							<FieldErrorMessage errors={errors} name="categoryType" />
						</Col>
					</Row>
				)}
				{currentCategory?.name === "Other Lodging" && (
					<Row key="type" gutter={[8, 8]}>
						<Col span={8}>
							<label htmlFor={"type"}>Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Campings / bungalows",
												value: "Campings / bungalows",
											},
											{
												label: "Private rentings",
												value: "Private rentings",
											},
											{
												label: "Other",
												value: "Other",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="categoryType"
							/>
							<FieldErrorMessage errors={errors} name="categoryType" />
						</Col>
					</Row>
				)}

				{currentCategory && currentCategory.name === "Design" && (
					<div key={"typeOfDesign"}>
						<Typography.Title level={4}>Designer Data</Typography.Title>
						<Row gutter={[8, 8]}>
							<Col span={8}>
								<label htmlFor={"typeOfDesign"}>Type Of Design</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "Furniture",
													value: "Furniture",
												},
												{
													label: "Jewelry design",
													value: "Jewelry design",
												},
												{
													label: "Decorative Items",
													value: "Decorative Items",
												},
												{
													label: "Fashion design",
													value: "Fashion design",
												},
												{
													label: "Souks",
													value: "Souks",
												},
												{
													label: "Upcycling",
													value: "Upcycling",
												},
											]}
											mode="multiple"
											{...field}
										/>
									)}
									name="typeOfDesign"
								/>
								<FieldErrorMessage errors={errors} name="typeOfDesign" />
							</Col>
						</Row>
					</div>
				)}
				{currentCategory && currentCategory.name === "Lebanese Designers" && (
					<div key={"typeOfDesign"}>
						<Typography.Title level={4}>Designer Data</Typography.Title>
						<Row gutter={[8, 8]}>
							<Col span={8}>
								<label htmlFor={"typeOfDesign"}>Type Of Design</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "Decorative items",
													value: "Decorative items",
												},
												{
													label: "Fashion design",
													value: "Fashion design",
												},
												{
													label: "Furniture design ",
													value: "Furniture design ",
												},
												{
													label: "Jewelry design ",
													value: "Jewelry design ",
												},
											]}
											mode="multiple"
											{...field}
										/>
									)}
									name="typeOfDesign"
								/>
								<FieldErrorMessage errors={errors} name="typeOfDesign" />
							</Col>
						</Row>
					</div>
				)}
				{currentCategory?.name === "Handicrafts" && (
					<div key={"Handicrafts"}>
						<Typography.Title level={4}>Handcraft Data</Typography.Title>
						<Row gutter={[8, 8]}>
							<Col span={8}>
								<label htmlFor={"typeOfHandicraft"}>Type Of Handcraft</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											options={[
												{
													label: "Copper",
													value: "Copper",
												},
												{
													label: "Glass",
													value: "Glass",
												},
												{
													label: "Leather",
													value: "Leather",
												},
												{
													label: "Metal",
													value: "Metal",
												},
												{
													label: "NGO",
													value: "NGO",
												},
												{
													label: "Needlework",
													value: "Needlework",
												},

												{
													label: "Pottery",
													value: "Pottery",
												},
												{
													label: "Soap",
													value: "Soap",
												},
												{
													label: "Wax",
													value: "Wax",
												},
												{
													label: "Weaving",
													value: "Weaving",
												},
												{
													label: "Wood",
													value: "Wood",
												},
												{
													label: "Other Type",
													value: "Other type",
												},
											]}
											style={{ width: "100%" }}
											mode="tags"
											{...field}
										/>
									)}
									name="typeOfHandicraft"
								/>
								<FieldErrorMessage errors={errors} name="typeOfHandicraft" />
							</Col>
						</Row>
					</div>
				)}
				{currentCategory?.name === "Guesthouses" && (
					<div key="GuestHouses">
						<Typography.Title level={4}>
							Guest House/Hotel Data
						</Typography.Title>
						<Row gutter={[8, 8]}>
							<Col span={6}>
								<label htmlFor={"numberOfRooms"}>Number Of Rooms</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "1 to 4",
													value: "1 to 4",
												},
												{
													label: "5 to 10",
													value: "5 to 10",
												},
												{
													label: "Above 10",
													value: "Above 10",
												},
											]}
											{...field}
										/>
									)}
									name="numberOfRooms"
								/>
								<FieldErrorMessage errors={errors} name="numberOfRooms" />
							</Col>

							<Col span={6}>
								<label htmlFor={"numberOfGuests"}>Number Of Guests</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "1 to 8",
													value: "1 to 8",
												},
												{
													label: "9 to 16",
													value: "9 to 16",
												},
												{
													label: "Above 16",
													value: "Above 16",
												},
											]}
											{...field}
										/>
									)}
									name="numberOfGuests"
								/>
								<FieldErrorMessage errors={errors} name="numberOfGuests" />
							</Col>

							<Col span={6}>
								<label htmlFor={"priceRange"}>Price Range</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "1",
													value: 1,
												},
												{
													label: "2",
													value: 2,
												},
												{
													label: "3",
													value: 3,
												},
											]}
											{...field}
										/>
									)}
									name="priceRange"
								/>
								<FieldErrorMessage errors={errors} name="priceRange" />
							</Col>
						</Row>
					</div>
				)}

				{currentCategory?.name === "Boutique Hotels" && (
					<div key="GuestHouses">
						<Typography.Title level={4}>
							Boutique Hotels Filters
						</Typography.Title>
						<Row gutter={[8, 8]}>
							<Col span={6}>
								<label htmlFor={"numberOfRooms"}>Number Of Rooms</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "1 to 6",
													value: "1 to 6",
												},

												{
													label: "Above 6",
													value: "Above 6",
												},
											]}
											{...field}
										/>
									)}
									name="numberOfRooms"
								/>
								<FieldErrorMessage errors={errors} name="numberOfRooms" />
							</Col>

							<Col span={6}>
								<label htmlFor={"numberOfGuests"}>Number Of Guests</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "1 to 12",
													value: "1 to 12",
												},
												{
													label: "Above 12",
													value: "Above 12",
												},
											]}
											{...field}
										/>
									)}
									name="numberOfGuests"
								/>
								<FieldErrorMessage errors={errors} name="numberOfGuests" />
							</Col>

							<Col span={6}>
								<label htmlFor={"priceRange"}>Price Range</label>
								<Controller
									control={control}
									render={({ field }) => (
										<Select
											style={{ width: "100%" }}
											options={[
												{
													label: "1",
													value: 1,
												},
												{
													label: "2",
													value: 2,
												},
												{
													label: "3",
													value: 3,
												},
											]}
											{...field}
										/>
									)}
									name="priceRange"
								/>
								<FieldErrorMessage errors={errors} name="priceRange" />
							</Col>
						</Row>
					</div>
				)}
				{currentCategory?.name === "Wineries & Distilleries" && (
					<Row key="wineries">
						<Col span={12}>
							<label htmlFor={"wineryType"}>Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Breweries",
												value: "Breweries",
											},
											{
												label: "Distilleries",
												value: "Distilleries",
											},
											{
												label: "Wineries",
												value: "Wineries",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="wineryType"
							/>
							<FieldErrorMessage errors={errors} name="wineryType" />
						</Col>
					</Row>
				)}

				{currentCategory?.name === "Pubs & Clubs" && (
					<Row key="pubType">
						<Col span={12}>
							<label htmlFor={""}>Pub Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Nightclubs",
												value: "Nightclubs",
											},
											{
												label: "Pubs",
												value: "Pubs",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="pubType"
							/>
							<FieldErrorMessage errors={errors} name="pubType" />
						</Col>
					</Row>
				)}

				{currentCategory?.name === "Bakeries & Pastries" && (
					<Row key="bakeryType">
						<Col span={12}>
							<label htmlFor={"bakeryType"}>Bakery And Pastries Type</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Bakeries",
												value: "Bakeries",
											},
											{
												label: "Oriental sweets",
												value: "Oriental sweets",
											},
											{
												label: "Pastries",
												value: "Pastries",
											},
											{
												label: "Other",
												value: "Other",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="bakeryType"
							/>
							<FieldErrorMessage errors={errors} name="bakeryType" />
						</Col>
					</Row>
				)}

				{currentCategory?.name === "Spa & Wellness" && (
					<Row key="spaType">
						<Col span={12}>
							<label htmlFor={"spaType"}>Spa & Welness</label>
							<Controller
								control={control}
								render={({ field }) => (
									<Select
										style={{ width: "100%" }}
										options={[
											{
												label: "Gyms",
												value: "Gyms",
											},
											{
												label: "Spas",
												value: "Spas",
											},
											{
												label: "Yoga",
												value: "Yoga",
											},
											{
												label: "Other",
												value: "Other",
											},
										]}
										mode="tags"
										{...field}
									/>
								)}
								name="spaType"
							/>
							<FieldErrorMessage errors={errors} name="spaType" />
						</Col>
					</Row>
				)}

				<Row key="thumbnail">
					<Col span={12}>
						<label htmlFor={"thumbnailImage"}>Thumbnail Image</label>

						<Controller
							render={({ field }) => <FileUploadV2 {...field} singleValue />}
							control={control}
							name="thumbnailImage"
						/>
						<FieldErrorMessage errors={errors} name="thumbnailImage" />
					</Col>
				</Row>
				<Row key="Images gallery">
					<Col span={12}>
						<label htmlFor={"imagesGallery"}>Images gallery</label>
						<Controller
							control={control}
							defaultValue={[]}
							render={({ field }) => <FileUploadV2 {...field} />}
							name="imagesGallery"
						/>
						<FieldErrorMessage errors={errors} name="imagesGallery" />
					</Col>
				</Row>
				<Row key="tags">
					<Col span={6}>
						<label htmlFor={"tags"}>Tags</label>
						<Controller
							control={control}
							defaultValue={[]}
							render={({ field }) => <TagsInput {...field} />}
							name="tags"
						/>
						<FieldErrorMessage errors={errors} name="tags" />
					</Col>
				</Row>
				<Row key="text">
					<Col span={24}>
						<label htmlFor={"text"}>Text</label>
						<Controller
							defaultValue=""
							control={control}
							rules={{ required: false }}
							render={({ field }) => <TextEditorInput {...field} />}
							name="text"
						/>
						<FieldErrorMessage errors={errors} name="text" />
					</Col>
				</Row>

				<Row key="contactEmails" style={{ width: "100%" }} gutter={12}>
					<Col span={12}>
						<label htmlFor={"phoneNumbers"}>Phone Numbers</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Select {...field} style={{ width: "100%" }} mode="tags" />
							)}
							name="phoneNumbers"
						/>
						<FieldErrorMessage errors={errors} name="phoneNumbers" />
					</Col>
					<Col span={12}>
						<label htmlFor={"emails"}>Emails</label>
						<Controller
							control={control}
							render={({ field }) => (
								<Select {...field} style={{ width: "100%" }} mode="tags" />
							)}
							name="emails"
						/>
						<FieldErrorMessage errors={errors} name="emails" />
					</Col>
					{/* <Col span={12}>
                        <EmailArrayField control={control} errors={errors} fieldName="emails" />
                    </Col> */}
				</Row>
				<Typography.Title level={4}>Geolocation</Typography.Title>
				<Row gutter={8}>
					<Col span={6}>
						<label htmlFor={"city"}>City</label>
						<Controller
							control={control}
							render={({ field }) => <CitySelector {...field} />}
							name="city"
						/>
						<FieldErrorMessage errors={errors} name="city" />
					</Col>
				</Row>
				<Row gutter={8}>
					<Col span={6}>
						<label htmlFor={"geolocation.latitude"}>Latitude</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="geolocation.latitude"
						/>
						<FieldErrorMessage errors={errors} name="geolocation.latitude" />
					</Col>
					<Col span={6}>
						<label htmlFor={"geolocation.longitude"}>Longitude</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="geolocation.longitude"
						/>
						<FieldErrorMessage errors={errors} name="geolocation.longitude" />
					</Col>
				</Row>
				<Typography.Title level={4}>Social Media</Typography.Title>
				<Row gutter={8}>
					<Col span={6}>
						<label htmlFor={"website"}>Website</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="website"
						/>
						<FieldErrorMessage errors={errors} name="website" />
					</Col>
					<Col span={6}>
						<label htmlFor={"socialMedia.facebook"}>Facebook</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="socialMedia.facebook"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.facebook" />
					</Col>
					<Col span={6}>
						<label htmlFor={"socialMedia.instagram"}>Instagram</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="socialMedia.instagram"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.instagram" />
					</Col>
					<Col span={6}>
						<label htmlFor={"socialMedia.twitter"}>Twitter</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="socialMedia.twitter"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.twitter" />
					</Col>
					<Col span={6}>
						<label htmlFor={"socialMedia.linkedIn"}>LinkedIn</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="socialMedia.linkedIn"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.linkedIn" />
					</Col>
					<Col span={6}>
						<label htmlFor={"socialMedia.pinterest"}>Pinterest</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="socialMedia.pinterest"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.pinterest" />
					</Col>
					<Col span={6}>
						<label htmlFor={"socialMedia.youtube"}>Youtube</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							name="socialMedia.youtube"
						/>
						<FieldErrorMessage errors={errors} name="socialMedia.youtube" />
					</Col>
				</Row>
			</Space>
			<div style={{ marginBottom: 10 }} />
			<Button
				loading={loading || loadingSubmit}
				htmlType="submit"
				role={"submit"}
			>
				Submit
			</Button>
		</form>
	);
};

export default GuideEntryForm;
