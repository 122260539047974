import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { CONFIGURATION } from "../config";
import { Helpers } from "../helpers";
import { Redux_Actions, store } from "../store";
export const Base_Url = Helpers.isProduction
	? process.env.REACT_APP_PRODUCTION_API_URL
	: process.env.REACT_APP_SIMULATE_PRODUCTION === "true"
		? process.env.REACT_APP_PRODUCTION_API_URL
		: process.env.REACT_APP_LOCAL_API_URL;
const axiosInstance = axios.create({
	baseURL: Base_Url,
	timeout: 600000,
	withCredentials: false,
});
axiosInstance.interceptors.request.use(
	async (config) => {
		const token = store.getState().user?.token;
		if (token)
			config.headers.Authorization = `Bearer ${store.getState().user.token}`;
		return config;
	},
	(error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	(error: AxiosError) => {
		// message.error()
		if (error.response) {
			if (error.response.status === 401) {
				message.error("You have been logged out from the server");
				store.dispatch({ type: Redux_Actions.logout });
			}
			if (error.response.data) {
				let err = error.response.data.message;
				if (err) {
					if (Array.isArray(err) && error.response.status === 400) {
						err.forEach((i) => {
							message.error(i.msg);
						});
					} else if (err) {
						message.error(err);
					}
				} else message.error(error.toString());
			} else {
				message.error(error.toString());
			}
		} else {
			message.error(error.toString());
		}

		return Promise.reject(error);
	},
);

export let Api = {
	upload: ({ formData }: any): Promise<AxiosResponse> =>
		axiosInstance.post("/file/upload", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
	login: ({
		username,
		password,
	}: { username: string; password: string }): Promise<any> =>
		axiosInstance.post("/login", {
			email: username,
			password,
		}),
	logout: (): Promise<AxiosResponse> => axiosInstance.get("/logout"),
	users: {
		create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
			axiosInstance.post("/users/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/users/table", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/users/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/users/update/${id}`, { ...args }),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/users/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/users/restore/${id}`),
		resetPassword: ({
			id,
			args,
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/users/reset/${id}`, { ...args }),
	},
	people: {
		create: ({ ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.post("/people/create", args),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/people/statistics"),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/people/table", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/people/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/people/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/people/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/people/restore/${id}`),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/people/list"),
		exportByCSV: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/people/export/csv", {
				params: args,
				responseType: "blob",
			}),
	},
	categories: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/categories/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/categories/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/categories/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/categories/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/categories/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/categories/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/categories/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/categories/restore/${id}`),
	},
	collections: {
		create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
			axiosInstance.post("/collections/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/collections/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/collections/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/collections/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/collections/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/collections/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/collections/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/collections/restore/${id}`),
	},
	promoCodes: {
		create: ({ ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.post(`/promoCodes/create`, args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/promoCodes/table", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/promoCodes/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/promoCodes/update/${id}`, {
				...args,
			}),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/promoCodes/statistics"),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/promoCodes/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/promoCodes/restore/${id}`),
	},
	priceRequest: {
		create: ({ ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.post(`/price-request/create`, args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/price-request/table", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/price-request/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/price-request/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/price-request/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/price-request/restore/${id}`),
	},
	materials: {
		create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
			axiosInstance.post("/materials/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/materials/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/materials/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/materials/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/materials/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/materials/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/materials/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/materials/restore/${id}`),
	},
	tags: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/tags/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/tags/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/tags/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/tags/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/tags/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/tags/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/tags/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/tags/restore/${id}`),
	},
	pages: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/pages/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/pages/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/pages/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/pages/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/pages/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/pages/update/${id}`, {
				...args,
			}),
	},
	itemOption: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/itemOption/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/itemOption/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/itemOption/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/itemOption/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/itemOption/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/itemOption/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/itemOption/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/itemOption/restore/${id}`),
	},
	items: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/items/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/items/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/items/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/items/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/items/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/items/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/items/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/items/restore/${id}`),
		singleWithVariations: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/item/variations`, { params: { itemId: id } }),
	},
	itemRequests: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/request/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/request/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/request/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/request/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/request/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/request/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/request/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/request/restore/${id}`),
	},
	contactForms: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/contact-form/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/contact-form/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/contact-form/statistics"),
		list: (): Promise<AxiosResponse> => axiosInstance.get("/contact-form/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/contact-form/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/contact-form/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/contact-form/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/contact-form/restore/${id}`),
	},
	itemVariation: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/itemVariation/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/itemVariation/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/itemVariation/statistics"),
		list: (): Promise<AxiosResponse> =>
			axiosInstance.get("/itemVariation/list"),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/itemVariation/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/itemVariation/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/itemVariation/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/itemVariation/restore/${id}`),
	},
	emailSubscriptions: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/emailSubscriptions/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/emailSubscriptions/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/emailSubscriptions/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/emailSubscriptions/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/emailSubscriptions/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/emailSubscriptions/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/emailSubscriptions/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/emailSubscriptions/restore/${id}`),
	},

	guideCategories: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/guideCategories/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/guideCategories/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/guideCategories/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/guideCategories/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/guideCategories/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/guideCategories/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/guideCategories/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/guideCategories/restore/${id}`),
	},
	guideEntries: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/guideEntries/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/guideEntries/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/guideEntries/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/guideEntries/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/guideEntries/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/guideEntries/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/guideEntries/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/guideEntries/restore/${id}`),
		exportByCSV: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/guideEntries/export/csv", { params: args }),
	},
	packages: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/packages/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/packages/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/packages/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/packages/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/packages/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/packages/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/packages/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/packages/restore/${id}`),
	},
	regions: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/regions/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/regions/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/regions/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/regions/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/regions/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/regions/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/regions/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/regions/restore/${id}`),
	},
	districts: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/districts/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/districts/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/districts/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/districts/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/districts/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/districts/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/districts/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/districts/restore/${id}`),
	},
	cities: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/cities/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/cities/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/cities/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/cities/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/cities/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/cities/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/cities/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/cities/restore/${id}`),
	},
	dataImports: {
		create: ({ ...args }: any): Promise<AxiosResponse> =>
			axiosInstance.post("/dataImports/create", args),
		table: (args: any): Promise<AxiosResponse> =>
			axiosInstance.get("/dataImports/table", { params: args }),
		statistics: (): Promise<AxiosResponse> =>
			axiosInstance.get("/dataImports/statistics"),
		list: (args?: any): Promise<AxiosResponse> =>
			axiosInstance.get("/dataImports/list", { params: args }),
		single: (id: number): Promise<AxiosResponse> =>
			axiosInstance.get(`/dataImports/single/${id}`),
		update: ({
			id,
			...args
		}: { id: number; args: any }): Promise<AxiosResponse> =>
			axiosInstance.put(`/dataImports/update/${id}`, {
				...args,
			}),
		delete: (id: number): Promise<AxiosResponse> =>
			axiosInstance.delete(`/dataImports/delete/${id}`),
		restore: (id: number): Promise<AxiosResponse> =>
			axiosInstance.post(`/dataImports/restore/${id}`),
	},
};
