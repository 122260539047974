import { Button, Col, Input, message, Result, Row, Select, Space } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";

const DataImportForm = () => {
  let { id }: any = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm();
  let history = useHistory();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/dataImports");
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.dataImports.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.dataImports.create,
    updateApiCall: Api.dataImports.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/dataImports">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"name"}>Type</label>
            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  style={{ width: "100%" }}
                  options={[
                    { label: "Regions", value: "REGIONS" },
                    { label: "Districts", value: "DISTRICTS" },
                    { label: "Cities", value: "CITIES" },
                    { label: "Guide Entries", value: "GUIDE_ENTRIES" },
                  ]}
                />
              )}
              rules={{ required: true }}
              name="type"
            />
            <FieldErrorMessage errors={errors} name="type" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={"file"}>Import File</label>
            <Controller
              control={control}
              render={({ field }) => <FileUploadV2 singleValue {...field} />}
              name="file"
            />
            <FieldErrorMessage errors={errors} name="file" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>
        Submit
      </Button>
    </form>
  );
};

export default DataImportForm;
