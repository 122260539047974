import React from "react";
import {
	Controller,
	type Control,
	type FieldErrors,
	type FieldValues,
	type Path,
} from "react-hook-form";
import { Col, Row, Input, Button, Space, Card } from "antd";
import { FieldErrorMessage } from "./FieldErrorMessage";
import Icon, { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

type PhoneArrayField<T extends FieldValues> = {
	control: Control<T>;
	fieldName: Path<T>;
	errors: FieldErrors<any>;
	useFieldArrayFields: any;
};
const PhoneArrayFieldSimple = <T extends object>({
	control,
	fieldName,
	errors,
	useFieldArrayFields,
}: PhoneArrayField<T>) => {
	const { fields, append, remove } = useFieldArrayFields;

	return (
		<Card bodyStyle={{ padding: 15 }} title="Phone Numbers">
			<Space style={{ width: "100%" }} direction="vertical">
				{fields.map((item: any, index: number) => {
					return (
						<Row
							key={item.value || index}
							style={{ width: "100%" }}
							gutter={12}
						>
							<Col span={24}>
								<Input.Group size="default">
									<Row gutter={2}>
										<Col span={22}>
											<Controller
												defaultValue={item.value}
												control={control}
												rules={{ required: true }}
												render={({ field }) => (
													<Input
														placeholder="Number"
														id={`${fieldName}[${index}].value`}
														{...field}
														value={
															field.value as
																| string
																| number
																| readonly string[]
																| undefined
														}
													/>
												)}
												name={`${fieldName}[${index}].value` as Path<T>}
											/>
										</Col>
										<Col span={2}>
											<Button
												block
												style={{ height: "100%" }}
												onClick={() => {
													remove(index);
												}}
												icon={<DeleteOutlined />}
											/>
										</Col>
									</Row>
								</Input.Group>

								<FieldErrorMessage
									errors={errors}
									name={`${fieldName} ${index + 1}`}
								/>
							</Col>
						</Row>
					);
				})}
				<Button
					block
					onClick={() => {
						append({ value: "" });
					}}
					icon={<PlusOutlined />}
				/>
				<FieldErrorMessage errors={errors} name={fieldName} />
			</Space>
		</Card>
	);
};

export default PhoneArrayFieldSimple;
