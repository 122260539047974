import { useEffect, useState } from "react";
import { Api } from ".";

export const useGuideEntriesList: () => [Record<string, any>[], boolean] =
	() => {
		const [data, setData] = useState([]);
		const [loading, setLoading] = useState(true);

		useEffect(() => {
			setLoading(true);
			Api.guideEntries
				.list()
				.then((res) => {
					setData(res.data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setLoading(false);
				});
		}, []);

		return [data, loading];
	};
