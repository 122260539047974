import React from "react";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { Table } from "./Table";
import { Api } from "../api";
import BasicTableFilter from "./BasicTableFilter";
import { priorityOptions } from "./PeopleForm";

const columns = [
	{
		title: "First Name",
		dataIndex: "firstName",
		key: "firstName",
		sorter: true,
	},
	{
		title: "Last Name",
		dataIndex: "lastName",
		key: "lastName",
		sorter: true,
	},
	{
		title: "Company",
		dataIndex: "company",
		key: "company",
		sorter: true,
	},
	{
		title: "Job Title",
		dataIndex: "jobTitle",
		key: "jobTitle",
		sorter: true,
	},
	{
		title: "Category",
		dataIndex: ["category", "name"],
		key: "category",
		sorter: true,
	},
	{
		title: "Tags",
		dataIndex: "tagsV2",
		key: "tagsV2",
		sorter: true,
		render: (value: string[]) =>
			value.length <= 1 ? (
				value
			) : (
				<>
					{value[0]}{" "}
					<span
						style={{
							backgroundColor: "#808080",
							borderRadius: "100%",
							fontSize: 10,
							padding: 3,
							color: "white",
							fontWeight: "bold",
						}}
					>
						+{value.length - 1}
					</span>
				</>
			),
	},
	{
		title: "Priority",
		dataIndex: "priority",
		key: "priority",
		sorter: true,
		render: (value: number) => {
			const { label, backgroundColor } = priorityOptions[value ?? 0];
			return (
				<div
					style={{
						padding: "2px 6px",
						borderRadius: 4,
						backgroundColor,
						color: "white",
						fontWeight: "bold",
					}}
				>
					<span>{label}</span>
				</div>
			);
		},
	},
	{
		title: "Emails",
		dataIndex: "emails",
		key: "emails",
		render: (emails: { value: string }[]) =>
			emails.length ? (
				emails.length === 1 ? (
					emails[0].value
				) : (
					<>
						{emails[0].value}{" "}
						<span
							style={{
								backgroundColor: "#808080",
								borderRadius: "100%",
								padding: 4,
								color: "white",
								fontWeight: "bold",
							}}
						>
							emails +{emails.length - 1}
						</span>
					</>
				)
			) : null,
	},
	{
		title: "Phones",
		dataIndex: "phones",
		key: "phones",
		render: (phones: { value: string }[]) =>
			phones.length ? (
				phones.length === 1 ? (
					phones[0].value
				) : (
					<>
						{phones[0].value}{" "}
						<span
							style={{
								backgroundColor: "#808080",
								borderRadius: "100%",
								fontSize: 10,
								padding: 3,
								color: "white",
								fontWeight: "bold",
							}}
						>
							+{phones.length - 1}
						</span>
					</>
				)
			) : null,
	},
];
const PeopleTable = () => {
	return (
		<Space style={{ width: "100%" }} direction="vertical">
			<Table
				tableId={"peopleTable"}
				deleteApiCall={Api.people.delete}
				allowDelete={true}
				restoreApiCall={Api.people.restore}
				// biome-ignore lint/suspicious/noExplicitAny: <explanation>
				actions={(record: any) => (
					<Link to={`/people/update/${record._id}`}>View</Link>
				)}
				filterDefinitions={[
					{
						key: "firstName",
						type: "search",
						label: "First Name",
					},
					{
						key: "lastName",
						type: "search",
						label: "Last Name",
					},
					{
						key: "company",
						type: "search",
						label: "Company",
					},
					{
						key: "priority",
						type: "priority",
						label: "Priority",
					},
					{
						key: "category",
						type: "multiValue",
						label: "Category",
						apiFetchCall: Api.categories.list,
						apiAddCall: Api.categories.create,
					},
					{
						key: "tags",
						type: "multiValue",
						label: "Tags",
						apiFetchCall: Api.tags.list,
						apiAddCall: Api.tags.create,
					},
					{
						key: "isEntity",
						type: "switch",
						label: "Entity Only",
					},
				]}
				Filter={BasicTableFilter}
				apiCall={Api.people.table}
				exportApiCall={{
					csv: Api.people.exportByCSV,
				}}
				columns={columns}
			/>
		</Space>
	);
};

export default PeopleTable;
