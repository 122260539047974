import React, { useEffect, useCallback, useRef, useState } from "react";
import { Row, Col, Input, Button, Checkbox, Space } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "./FieldErrorMessage";
import SelectWithApiData from "./SelectWithApiData";
import DataExportButton from "./DataExportButton";
import { Api } from "../api";
import { AxiosResponse } from "axios";

export type BasicTableFilterProp = {
	value: any;
	onChange: (value: any) => void;
	tableId: string;
	exportFields?: { path: string; label: string }[];
	exportApiCall?: {
		csv?: (args: any) => Promise<AxiosResponse>;
		excel?: (args: any) => Promise<AxiosResponse>;
		word?: (args: any) => Promise<AxiosResponse>;
	};
	filterDefinitions?: any;
	deletedFilter?: boolean;
};
const default_definitions = [
	{
		key: "_id",
		type: "number",
		label: "ID",
		span: 3,
	},
	{
		key: "name",
		type: "search",
		label: "Name",
	},
];
const BasicTableFilter = ({
	value = {},
	onChange,
	filterDefinitions,
	deletedFilter = true,
	exportApiCall,
	exportFields,
	tableId,
}: BasicTableFilterProp) => {
	const {
		handleSubmit,
		control,
		reset,
		watch,

		formState: { errors },
	} = useForm({ defaultValues: value });
	const [definitions, setDefinitions] = useState(
		filterDefinitions || default_definitions,
	);

	useEffect(() => {
		if (deletedFilter)
			setDefinitions([
				...definitions,
				{
					key: "showDeleted",
					type: "showDeleted",
					excludeFromGenerator: true,
				},
			]);
	}, [deletedFilter]);
	const clearFilter = () => {
		reset({ showDeleted: false as any });
		onChange({
			filter: {},
			definitions,
		});
	};

	const handleChange = useCallback(
		(v: any) => {
			onChange({
				filter: v,
				definitions,
			});
		},
		[onChange],
	);

	const renderDefinition = (definition: any) => {
		switch (definition.type) {
			case "multiValue":
				return (
					<Col key={definition.key} span={definition.span || 6}>
						<div className="">
							<label className="mr-10" htmlFor={definition.key}>
								{definition.label}:{" "}
							</label>
							<SelectWithApiData
								onChange={(data) => {
									onChange({
										filter: { ...value, [definition.key]: data },
										definitions,
									});
								}}
								multiValue
								apiAddCall={definition.apiAddCall}
								labelField={definition.labelField}
								apiFetchCall={definition.apiFetchCall}
								dataMappingFunction={definition.dataMappingFunction}
								value={value[definition.key]}
							/>
							{/* <Controller
                                control={control}
                                as={}
                                name={definition.key}
                            /> */}
						</div>
					</Col>
				);
			case "checkbox":
				return (
					<div
						style={{ display: "flex", alignItems: "center", marginTop: 15 }}
						className=""
					>
						<label className="mr-10" htmlFor={"showDeleted"}>
							{definition.label}:{" "}
						</label>

						<Checkbox
							checked={value[definition.key]}
							onChange={(e) => {
								onChange({
									filter: { ...value, [definition.key]: e.target.checked },
									definitions,
								});
							}}
						/>
					</div>
				);
			default:
				return (
					<Col key={definition.key} span={definition.span || 6}>
						<div className="">
							<label className="mr-10" htmlFor={definition.key}>
								{definition.label}:{" "}
							</label>
							<Input
								value={value[definition.key]}
								onChange={(e) => {
									onChange({
										filter: { ...value, [definition.key]: e.target.value },
										definitions,
									});
								}}
								id={definition.key}
							/>
						</div>
						<FieldErrorMessage
							style={{ textAlign: "center" }}
							errors={errors}
							name={definition.key}
							formatName={definition.label}
						/>
					</Col>
				);
		}
	};

	return (
		<div className="white-container" style={{ marginBottom: 12 }}>
			<form onSubmit={handleSubmit(handleChange)}>
				<Space size="large" style={{ width: "100%" }} direction="vertical">
					<Row style={{ width: "100%" }} gutter={12}>
						{definitions
							.filter((def: any) => !def.excludeFromGenerator)
							.map((def: any) => renderDefinition(def))}
					</Row>
					<Row justify="end">
						<Space size="small">
							<div className="">
								<label className="mr-10" htmlFor={"showDeleted"}>
									Show Deleted
								</label>

								<Checkbox
									checked={value.showDeleted}
									onChange={(e) => {
										onChange({
											filter: { ...value, showDeleted: e.target.checked },
											definitions,
										});
									}}
								/>
							</div>
							<Button htmlType="submit" size="small" type="dashed">
								Submit
							</Button>
							<Button size="small" onClick={clearFilter} danger>
								Reset
							</Button>
							{exportApiCall && (
								<>
									{exportApiCall.csv && (
										<DataExportButton
											exportFields={exportFields}
											tableId={tableId}
											apiCall={exportApiCall.csv}
											label="Export By CSV"
										/>
									)}
									{exportApiCall.excel && (
										<DataExportButton
											exportFields={exportFields}
											tableId={tableId}
											apiCall={exportApiCall.excel}
											label="Export By Excel"
										/>
									)}
									{exportApiCall.word && (
										<DataExportButton
											exportFields={exportFields}
											tableId={tableId}
											apiCall={exportApiCall.word}
											label="Export By Word"
										/>
									)}
								</>
							)}
						</Space>
					</Row>
				</Space>
			</form>
		</div>
	);
};

export default BasicTableFilter;
