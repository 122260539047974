import {
	Table as AntdTable,
	Space,
	Popconfirm,
	message,
	Row,
	Col,
	Dropdown,
	Menu,
} from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import { AxiosResponse } from "axios";
import React, { useEffect, useState, useCallback, ReactNode } from "react";
import { BasicTableFilterProp } from "./BasicTableFilter";
import { ReloadOutlined, ColumnHeightOutlined } from "@ant-design/icons";
import { useTrackedState, useDispatch } from "reactive-react-redux";
import { State, Redux_Actions } from "../store";

type TableProps = {
	columns: ColumnsType<any>;
	apiCall: (args: any) => Promise<AxiosResponse>;
	allowDelete?: boolean;
	exportApiCall?: {
		csv?: (args: any) => Promise<AxiosResponse>;
		excel?: (args: any) => Promise<AxiosResponse>;
		word?: (args: any) => Promise<AxiosResponse>;
	};
	deleteApiCall?: (args: any) => Promise<AxiosResponse>;
	restoreApiCall?: (args: any) => Promise<AxiosResponse>;
	Filter?: React.FC<BasicTableFilterProp>;
	actions?: any;
	filterDefinitions?: any;
	tableId: string;
	exportFields?: { path: string; label: string }[];
};
export const Table = ({
	apiCall,
	columns,
	exportApiCall,
	allowDelete = false,
	deleteApiCall,
	Filter,
	restoreApiCall,
	actions,
	filterDefinitions,
	exportFields,
	tableId,
}: TableProps) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination]: [
		PaginationProps,
		(pagination: PaginationProps) => void,
	] = useState({});

	// const [filterValue, onChangeFilterValue] = useState({});
	const [tableSize, setTableSize]: [any, any] = useState("middle");
	const state = useTrackedState<State>();
	const filterPath =
		state.tablesData[tableId] && state.tablesData[tableId].filter;
	const statePath =
		state.tablesData[tableId] && state.tablesData[tableId].state;
	const filterValue = state.tablesData[tableId]
		? state.tablesData[tableId].filter || {}
		: {};
	const request = state.tablesData[tableId]
		? state.tablesData[tableId].state || { current: 1, limit: 10 }
		: { current: 1, limit: 10 };
	const dispatch = useDispatch();
	// const [request, setRequest]: [any, any] = useState({ current: 1, limit: 10 });
	// console.log(request);
	const onChangeFilterValue = (value: any) => {
		dispatch({
			type: Redux_Actions.setTableFilter,
			payload: {
				tableId,
				data: value,
			},
		});
		fetchData({ ...request, filter: value });
	};
	const setRequest = (value: any) => {
		dispatch({
			type: Redux_Actions.setTableState,
			payload: {
				tableId,
				data: value,
			},
		});
	};

	const fetchData = useCallback(
		(req: any = request) => {
			// console.log("calling", req);
			setLoading(true);

			apiCall(req)
				.then((res: AxiosResponse) => {
					// console.log(res);
					setData(res.data.data.docs);
					setPagination({
						pageSize: res.data.data.limit,
						total: res.data.data.totalDocs,
						current: res.data.data.page,
						showTotal: (items) => {
							return "Total " + items;
						},
					});
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[apiCall],
	);

	useEffect(() => {
		fetchData();
	}, []);

	// useEffect(() => {
	//     fetchData();
	// }, [statePath, apiCall, fetchData]);

	// useEffect(() => {
	//     setRequest(;
	// }, [filterValue, filterPath]);

	const handleTableChange = (
		currentPagination: PaginationProps,
		filters: any,
		sorter: SorterResult<any> | SorterResult<any>[],
	) => {
		setPagination({ ...currentPagination });
		let sort: any = {};
		if (!Array.isArray(sorter)) {
			if (sorter.field && sorter.order) {
				sort[sorter.field.toString()] =
					sorter.order === "ascend" ? "asc" : "desc";
			}
		}
		// setRequest()
		fetchData({
			...request,
			current: currentPagination.current,
			limit: currentPagination.pageSize,
			sort,
		});
	};

	const onConfirmDelete = (record: any) => {
		if (!deleteApiCall) throw new Error("Delete Api call not defined");
		else {
			setLoading(true);
			deleteApiCall(record._id)
				.then((res) => {
					message.success("Deleted");
				})
				.finally(() => {
					fetchData({ ...request, filter: filterValue });
				});
		}
	};
	const onConfirmRestor = (record: any) => {
		if (!restoreApiCall) throw new Error("Restore Api call not defined");
		else {
			setLoading(true);
			restoreApiCall(record._id)
				.then((res) => {
					message.success("Restored");
				})
				.finally(() => {
					fetchData({ ...request, filter: filterValue });
				});
		}
	};
	const menu = (
		<Menu>
			<Menu.Item onClick={() => setTableSize("small")}>Small</Menu.Item>
			<Menu.Item onClick={() => setTableSize("middle")}>Medium</Menu.Item>
			<Menu.Item onClick={() => setTableSize("large")}>Large</Menu.Item>
		</Menu>
	);

	const mapColumns = () => {
		let cols = [
			...columns.map((col) => {
				col.align = "center";
				return col;
			}),
		];
		if (allowDelete || actions) {
			cols.push({
				title: "Action",
				align: "center" as "center",
				width: "10%",
				key: "action",
				render: (text, record) => (
					<Space size="middle">
						{allowDelete &&
							(record.deleted ? (
								<Popconfirm
									title="Confirm Restore?"
									onConfirm={() => {
										onConfirmRestor(record);
									}}
									okText="Yes"
									cancelText="No"
								>
									<a href="#">Restore</a>
								</Popconfirm>
							) : (
								<Popconfirm
									title="Comfirm Delete?"
									onConfirm={() => {
										onConfirmDelete(record);
									}}
									okText="Yes"
									cancelText="No"
								>
									<a href="#">Delete</a>
								</Popconfirm>
							))}

						{actions && actions(record)}
					</Space>
				),
			});
		}
		return cols;
	};

	return (
		<div>
			{Filter && (
				<Filter
					exportApiCall={exportApiCall}
					filterDefinitions={filterDefinitions}
					value={filterValue && filterValue.filter}
					tableId={tableId}
					exportFields={exportFields}
					onChange={onChangeFilterValue}
				/>
			)}
			<div style={{ background: "white" }}>
				<Row justify="end" style={{ padding: 10 }}>
					<Col>
						<Space size="large">
							<Dropdown overlay={menu}>
								<ColumnHeightOutlined
									style={{ fontSize: 18, cursor: "pointer" }}
								/>
							</Dropdown>

							<ReloadOutlined
								onClick={() => fetchData()}
								style={{ fontSize: 18, cursor: "pointer" }}
							/>
						</Space>
					</Col>
				</Row>
			</div>
			<AntdTable
				// style={{ padding: "10px 0px 10px 0px" }}
				size={tableSize}
				loading={loading}
				columns={mapColumns()}
				rowKey={(record: any) => record._id}
				dataSource={data}
				pagination={{
					...pagination,
					showSizeChanger: true,
					showQuickJumper: true,
				}}
				// loading={this.state.loading}
				onChange={handleTableChange}
			/>
		</div>
	);
};
