import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useTrackedState } from "reactive-react-redux";
import type { AxiosResponse } from "axios";
import type { State } from "../store";

const DataExportButton: React.FC<any> = ({
	apiCall,
	tableId,
	exportFields,
	label,
}) => {
	const state = useTrackedState<State>();
	const filterValue = state.tablesData[tableId]
		? state.tablesData[tableId].filter || {}
		: {};
	const [loading, setLoading] = useState(false);

	const onExport = () => {
		setLoading(true);
		apiCall({ filter: filterValue, exportFields }).then(
			(res: AxiosResponse) => {
				//   let a = document.createElement("a");
				// //   document.body.appendChild(a);
				//   a.target = "_blank";
				//   a.href = ImagesHelpers.formatUrlOnly(res.data);
				//   a.download = "export.csv";
				//   a.click();
				//   document.body.removeChild(a);

				var headers = res.headers;
				var blob = new Blob([res.data], { type: headers["content-type"] });
				var link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);

				let headerLine = headers["content-disposition"];
				let startFileNameIndex = headerLine.indexOf('"') + 1;
				let endFileNameIndex = headerLine.lastIndexOf('"');
				let filename = headerLine.substring(
					startFileNameIndex,
					endFileNameIndex,
				);
				link.download = filename;
				link.click();
				setLoading(false);
			},
		);
	};
	return (
		<Button
			icon={<UploadOutlined />}
			size="small"
			loading={loading}
			onClick={onExport}
		>
			{label}
		</Button>
	);
};

export default DataExportButton;
