import React from 'react';
import { Controller, type Control, type FieldErrors, type FieldValues, type Path } from 'react-hook-form';
import { Col, Row, Input, Button, Space, Card } from 'antd';
import { FieldErrorMessage } from './FieldErrorMessage';
import Icon, { DeleteOutlined, PlusOutlined, MailOutlined } from '@ant-design/icons';

type EmailArrayField<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  errors: FieldErrors<any>;
  useFieldArrayFields: any;
};
const EmailArrayField = <T extends object>({ control, fieldName, errors, useFieldArrayFields }: EmailArrayField<T>) => {
  const { fields, append, remove } = useFieldArrayFields;
  return (
    <Card bodyStyle={{ padding: 15 }} title="Emails">
      <Space style={{ width: '100%' }} direction="vertical">
        {fields.map((item: any, index: number) => {
          return (
            <div key={item.value || index}>
              <Row style={{ width: '100%' }} gutter={12}>
                <Col span={22}>
                  <Controller
                    defaultValue={item.value}
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        prefix={<MailOutlined />}
                        placeholder="Email"
                        id={`${fieldName}[${index}].value`}
                        {...field}
                        value={field.value as string | number | readonly string[] | undefined}
                      />
                    )}
                    name={`${fieldName}[${index}].value` as Path<T>}
                  />
                </Col>
                <Col span={2}>
                  <Button
                    block
                    style={{ height: '100%' }}
                    onClick={() => {
                      remove(index);
                    }}
                    icon={<DeleteOutlined />}
                  />
                </Col>
              </Row>
              <FieldErrorMessage errors={errors} name={`${fieldName} ${index + 1}`} />
            </div>
          );
        })}
        <Button
          block
          onClick={() => {
            append({ value: '' });
          }}
          icon={<PlusOutlined />}
        />
        <FieldErrorMessage errors={errors} name={fieldName} />
      </Space>
    </Card>
  );
};

export default EmailArrayField;
