import { Button, Col, Input, message, Result, Row, Select, Space } from 'antd';
import { entries } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Api } from '../api';
import { useGuideEntriesList } from '../api/useGuideEntriesList';
import { useSubmitForm } from '../helpers/forms';
import { ImagesHelpers } from '../helpers/images';
import { useLoadFormData } from '../hooks/useLoadFormData';
import { FieldErrorMessage } from './FieldErrorMessage';
import { FileUploader } from './FileUploader';
import FileUploadV2 from './fileUploadV2/FileUploadV2';
import GuideCategoriesSelector from './GuideCategoriesSelector';
import { TagsInput } from './TagsInput';
import TextEditorInput from './TextEditorInput';

const PackageForm = () => {
  let { id }: any = useParams();
  // const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm({
    // shouldUnregister: false,
    // defaultValues,
  });
  let history = useHistory();
  const onSuccess = () => {
    message.success('Saved');
    history.push('/packages');
  };
  const [guideEntries, guideEntriesLoading] = useGuideEntriesList();
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];
  const [notFound, loading] = useLoadFormData({
    apiCall: Api.packages.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.packages.create,
    updateApiCall: Api.packages.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/packages">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: '100%' }} direction="vertical">
        <Row style={{ width: '100%' }} gutter={12}>
          <Col span={6}>
            <label htmlFor={'name'}> Name</label>
            <Controller
              control={control}
              render={({ field }) => <Input {...field} />}
              rules={{ required: true }}
              name="name"
            />
            <FieldErrorMessage errors={errors} name="name" />
          </Col>
        </Row>
        <Row key="thumbnail">
          <Col span={12}>
            <label htmlFor={'thumbnailImage'}>Thumbnail Image</label>

            <Controller
              render={({ field }) => <FileUploadV2 {...field} singleValue />}
              control={control}
              name="thumbnailImage"
            />
            <FieldErrorMessage errors={errors} name="thumbnailImage" />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={18}>
            <label htmlFor={'entries'}>Entries</label>
            <Controller
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    mode={'multiple'}
                    optionFilterProp="label"
                    options={guideEntries.map((d) => ({
                      label: d.name,
                      value: d._id,
                    }))}
                    loading={guideEntriesLoading}
                    {...field}
                    value={guideEntriesLoading ? [] : field.value}
                  />
                );
              }}
              rules={{ required: true }}
              name="entries"
            />
            <FieldErrorMessage errors={errors} name="entries" />
          </Col>
        </Row>

        <Row key="Description">
          <Col span={24}>
            <label htmlFor={'description'}>Description</label>
            <Controller
              defaultValue=""
              control={control}
              rules={{ required: false }}
              render={({ field }) => <TextEditorInput {...field} />}
              name="description"
            />
            <FieldErrorMessage errors={errors} name="description" />
          </Col>
        </Row>

        <Row key="tags">
          <Col span={6}>
            <label htmlFor={'tags'}>Tags</label>
            <Controller
              control={control}
              defaultValue={[]}
              render={({ field }) => <TagsInput {...field} />}
              name="tags"
            />
            <FieldErrorMessage errors={errors} name="tags" />
          </Col>
        </Row>
        <Row key="collections">
          <Col span={6}>
            <label htmlFor={'collections'}>Collections</label>
            <Controller
              control={control}
              defaultValue={[]}
              render={({ field }) => <TagsInput {...field} />}
              name="collections"
            />
            <FieldErrorMessage errors={errors} name="collections" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button loading={loading || loadingSubmit} htmlType="submit" role={'submit'}>
        Submit
      </Button>
    </form>
  );
};

export default PackageForm;
