import { Button, Col, Input, message, Result, Row, Space } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import DistrictSelector from "./DistrictSelector";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";

const CityForm = () => {
	let { id }: any = useParams();
	// const [notFound, setNotFound] = useState(false);
	const {
		handleSubmit,
		control,
		reset,

		formState: { errors },
	} = useForm();
	let history = useHistory();
	const onSuccess = () => {
		message.success("Saved");
		history.push("/cities");
	};
	// const imageFields = [
	//     {
	//         name: "image",
	//         singleValue: true,
	//     }
	// ];

	const [notFound, loading] = useLoadFormData({
		apiCall: Api.cities.single,
		id,
		setValue: reset,
		//  imageFields
	});
	const [loadingSubmit, submit] = useSubmitForm({
		createApiCall: Api.cities.create,
		updateApiCall: Api.cities.update,
		onSuccess,
		//  imageFields,
		updateId: id,
	});

	if (notFound)
		return (
			<Result
				status="404"
				title="404"
				subTitle="Sorry, document does not exist"
				extra={
					<Button type="primary">
						<Link to="/cities">Back to list</Link>
					</Button>
				}
			/>
		);

	return (
		<form className="white-container" onSubmit={handleSubmit(submit)}>
			<Space size="middle" style={{ width: "100%" }} direction="vertical">
				<Row style={{ width: "100%" }} gutter={12}>
					<Col span={6}>
						<label htmlFor={"name"}>Name</label>
						<Controller
							control={control}
							render={({ field }) => <Input {...field} />}
							rules={{ required: true }}
							name="name"
						/>
						<FieldErrorMessage errors={errors} name="name" />
					</Col>

					<Col span={6}>
						<label htmlFor={"district"}>District</label>
						<Controller
							control={control}
							render={({ field }) => <DistrictSelector {...field} />}
							rules={{ required: true }}
							name="district"
						/>
						<FieldErrorMessage errors={errors} name="district" />
					</Col>
				</Row>
			</Space>
			<div style={{ marginBottom: 10 }} />
			<Button
				loading={loading || loadingSubmit}
				htmlType="submit"
				role={"submit"}
			>
				Submit
			</Button>
		</form>
	);
};

export default CityForm;
